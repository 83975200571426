@import url("https://cdn.jsdelivr.net/npm/@tabler/icons-webfont@latest/tabler-icons.min.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: #333843;
  --background-rgb: #f6fbfd;
  --background-overlay-rgb: 255, 255, 255;
  --nextui-secondary-foreground: 0 0% 0%;
}
[class^="ti-"],
[class*=" ti-"] {
  font-family: "Tabler Icons", sans-serif;
}

/* Start Variables Styles */
:root {
  --color-primary: theme("colors.primary.950");
  --color-primary-rgb: 90, 102, 241;
  --color-secondary: 96 165 250;
  --color-success: 34 197 94;
  --color-info: 76 117 207;
  --color-warning: 234 179 8;
  --color-danger: 244 63 94;
  --colourbackdrop: rgba(187, 216, 254, 1);
  --colourinputfield: rgba(245, 248, 251, 1);
  --colourprimary: rgba(16, 40, 71, 1);
  --neutralgraygray-25: rgba(249, 249, 252, 1);
  --neutralgraygray-400: rgba(133, 141, 157, 1);
  --primaryprimary-100: rgba(222, 222, 250, 1);
  --primaryprimary-50: rgba(239, 239, 253, 1);
  --primaryprimary-500: rgba(92, 89, 232, 1);
  --b1: theme("colors.white");
  --fallback-b1: theme("colors.white");
}

.dark {
  --color-primary: theme("colors.primary.500");
  --foreground-rgb: #ffffff;
  --background-rgb: #1e3759;
  --background-overlay-rgb: 0, 0, 0;
  --b1: theme("colors.primary.950");
  --fallback-b1: theme("colors.primary.950");
}

body {
  color: var(--foreground-rgb);
  background: var(--background-rgb);
}

@layer components {
  .tab {
    position: unset;
  }
  .tabs-boxed {
    @apply bg-white border border-[#E0E2E7] dark:bg-primary-950 dark:border-card-border-dark/15;
  }
  .tab-active {
    @apply !bg-[#BBD8FE] !text-primary-950 dark:!bg-secondary-400;
  }
  .badge {
    @apply py-3;
  }
  .btn-primary {
    @apply !bg-secondary-400 !text-dark-base !border-secondary-400;
  }
  .bg-content1 {
    @apply dark:bg-primary-950 dark:border-card-border-dark/15;
  }
  .border-default-200 {
    @apply dark:border-primary-900;
  }
  .bg-secondary {
    @apply !bg-secondary-400;
  }
  .centered {
    @apply flex items-center justify-center;
  }

  .ql-toolbar {
    @apply dark:!text-primary-100;
  }
  .has-error .custom-select {
    @apply !border-red-600;
  }

  .app-menu.sidebar-size-sm ~ div header.header {
    @apply lg:ml-20;
  }
}
@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
.dark .ql-toolbar {
  color: #dddddd !important;
}
.dark .ql-stroke {
  stroke: #dddddd !important;
}
.dark .ql-fill {
  fill: #dddddd !important;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  /* display: none !important; */
}

div::-webkit-scrollbar {
  width: 0px;
  display: none !important;
}
.table-scrollbar::-webkit-scrollbar {
  height: 8px;
  /* display: none; */
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffa815;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7c6d6d;
}

/* Start Custom Styles */
.page-header {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.main-content {
  margin-top: 71px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
}
@media (min-width: 480px) {
  .main-content {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}
.content {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
/* Add this to your global CSS file */
.scrollbar-hide {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.scrollbar-hide::-webkit-scrollbar {
  width: 0px; /* Chrome, Safari, Opera */
}

.hover\:scrollbar-show:hover::-webkit-scrollbar {
  width: 2px !important; /* Chrome, Safari, Opera */
  height: 2px !important; /* Chrome, Safari, Opera */
}
/* Add these custom scrollbar styles */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #ffa815 transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ffa815;
  border-radius: 20px;
  border: transparent;
}

/* For hover state */
.custom-scrollbar.hover-show::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.custom-scrollbar.hover-show:hover::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
}

.btn-soft-primary {
  background-color: rgb(var(--color-primary) / 0.1);
  color: rgb(var(--color-primary));
  --tw-ring-offset-color: #fff;
}
.btn-soft-primary:hover {
  background-color: rgb(var(--color-primary));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.btn-soft-primary:focus {
  --tw-ring-color: rgb(var(--color-primary));
}
.dark .btn-soft-primary:focus {
  --tw-ring-offset-color: rgb(255 255 255 / 0.1);
}
.btn-soft-secondary {
  background-color: rgb(var(--color-secondary) / 0.1);
  color: rgb(var(--color-secondary));
  --tw-ring-offset-color: #fff;
}
.btn-soft-secondary:hover {
  background-color: rgb(var(--color-secondary));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.btn-soft-secondary:focus {
  --tw-ring-color: rgb(var(--color-secondary));
}
.dark .btn-soft-secondary:focus {
  --tw-ring-offset-color: rgb(255 255 255 / 0.1);
}
.btn-soft-warning {
  background-color: rgb(var(--color-warning) / 0.1);
  color: rgb(var(--color-warning));
  --tw-ring-offset-color: #fff;
}
.btn-soft-warning:hover {
  background-color: rgb(var(--color-warning));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.btn-soft-warning:focus {
  --tw-ring-color: rgb(var(--color-warning));
}
.dark .btn-soft-warning:focus {
  --tw-ring-offset-color: rgb(255 255 255 / 0.1);
}
.btn-soft-danger {
  background-color: rgb(var(--color-danger) / 0.1);
  border-width: 0px;
  color: rgb(var(--color-danger));
  --tw-ring-offset-color: #fff;
}
.btn-soft-danger:hover {
  background-color: rgb(var(--color-danger));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.btn-soft-danger:focus {
  --tw-ring-color: rgb(var(--color-danger));
}
.dark .btn-soft-danger:focus {
  --tw-ring-offset-color: rgb(255 255 255 / 0.1);
}
.btn-soft-info {
  background-color: rgb(var(--color-info) / 0.1);
  color: rgb(var(--color-info));
  --tw-ring-offset-color: #fff;
}
.btn-soft-info:hover {
  background-color: rgb(var(--color-info));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.btn-soft-info:focus {
  --tw-ring-color: rgb(var(--color-info));
}
.dark .btn-soft-info:focus {
  --tw-ring-offset-color: rgb(255 255 255 / 0.1);
}
.btn-soft-success {
  background-color: rgb(var(--color-success) / 0.1);
  color: rgb(var(--color-success));
  --tw-ring-offset-color: #fff;
}
.btn-soft-success:hover {
  background-color: rgb(var(--color-success));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.btn-soft-success:focus {
  --tw-ring-color: rgb(var(--color-success));
}
.dark .btn-soft-success:focus {
  --tw-ring-offset-color: rgb(255 255 255 / 0.1);
}

/* Start:: Dashboard-7 Styles */
.shape-1,
.shape-2,
.shape-3,
.shape-4,
.shape-5,
.shape-6,
.shape-7,
.shape-8,
.shape-9,
.shape-10,
.shape-11,
.shape-12,
.shape-13 {
  position: absolute;
}
.shape-1 i,
.shape-2 i,
.shape-3 i,
.shape-4 i,
.shape-5 i,
.shape-6 i,
.shape-7 i,
.shape-8 i,
.shape-9 i,
.shape-10 i,
.shape-11 i,
.shape-12 i,
.shape-13 i {
  opacity: 0.2;
}

.shape-1 {
  top: 20%;
}

[dir="ltr"] .shape-1 {
  left: 20%;
}

[dir="rtl"] .shape-1 {
  right: 20%;
}

.shape-2 {
  top: 42%;
}

[dir="ltr"] .shape-2 {
  left: 5%;
}

[dir="rtl"] .shape-2 {
  right: 5%;
}

.shape-3 {
  bottom: 55%;
}

[dir="ltr"] .shape-3 {
  right: 15%;
}

[dir="rtl"] .shape-3 {
  left: 15%;
}

.shape-4 {
  top: 25%;
}

[dir="ltr"] .shape-4 {
  left: 55%;
}

[dir="rtl"] .shape-4 {
  right: 55%;
}

.shape-5 {
  bottom: 25%;
}

[dir="ltr"] .shape-5 {
  left: 50%;
}

[dir="rtl"] .shape-5 {
  right: 50%;
}

.shape-6 {
  bottom: 10%;
}

[dir="ltr"] .shape-6 {
  right: 10%;
}

[dir="rtl"] .shape-6 {
  left: 10%;
}

.shape-7 {
  bottom: 17%;
}

[dir="ltr"] .shape-7 {
  left: 20%;
}

[dir="rtl"] .shape-7 {
  right: 20%;
}

.shape-8 {
  bottom: 17%;
}

[dir="ltr"] .shape-8 {
  right: 20%;
}

[dir="rtl"] .shape-8 {
  left: 20%;
}

.shape-9 {
  top: 10%;
}

[dir="ltr"] .shape-9 {
  right: 25%;
}

[dir="rtl"] .shape-9 {
  left: 25%;
}

.shape-10 {
  top: 15%;
}

[dir="ltr"] .shape-10 {
  right: 5%;
}

[dir="rtl"] .shape-10 {
  left: 5%;
}

.shape-11 {
  bottom: 35%;
}

[dir="ltr"] .shape-11 {
  left: 15%;
}

[dir="rtl"] .shape-11 {
  right: 15%;
}

.shape-12 {
  top: 40%;
}

[dir="ltr"] .shape-12 {
  left: 25%;
}

[dir="rtl"] .shape-12 {
  right: 25%;
}

.shape-13 {
  bottom: 10%;
}

[dir="ltr"] .shape-13 {
  left: 10%;
}

[dir="rtl"] .shape-13 {
  right: 10%;
}

.shape-14 {
  bottom: -10%;
}

[dir="ltr"] .shape-14 {
  right: -10%;
}

[dir="rtl"] .shape-14 {
  left: -10%;
}

.shape-15 {
  bottom: -17%;
}

[dir="ltr"] .shape-15 {
  left: -20%;
}

[dir="rtl"] .shape-15 {
  right: -20%;
}

.shape-16 {
  bottom: -17%;
}

[dir="ltr"] .shape-16 {
  right: -20%;
}

[dir="rtl"] .shape-16 {
  left: -20%;
}

/* End:: Dashboard-7 Styles */
.wave {
  position: relative;
  animation: moveWave 2s linear infinite;
}

@keyframes moveWave {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(20%);
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 214 63% 17%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 215 61% 7%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}
/* @layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
} */
